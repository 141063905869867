// extracted by mini-css-extract-plugin
export var bannerShow = "banner-module--bannerShow--tlSGj";
export var black = "banner-module--black--qsOlX";
export var btn = "banner-module--btn--4hC5o";
export var btnBlock = "banner-module--btnBlock--6e6ax";
export var container = "banner-module--container--OhaVn";
export var darkColor = "banner-module--darkColor--OCjP0";
export var fixBottom = "banner-module--fixBottom--YfsmE";
export var hideMobBlock = "banner-module--hideMobBlock--5tTEL";
export var img = "banner-module--img--bdDkq";
export var mobBtn = "banner-module--mobBtn--oNTe5";
export var onlyMob = "banner-module--onlyMob--uNdXW";
export var red = "banner-module--red--ferHC";
export var secondContainer = "banner-module--secondContainer--LFB-R";
export var section = "banner-module--section--G0J97";
export var subtitle = "banner-module--subtitle--0lHrI";
export var text = "banner-module--text--UCooM";
export var textBlockContainer = "banner-module--textBlockContainer--NaMZS";
export var textItem = "banner-module--textItem--DSnnV";
export var title = "banner-module--title--q1VJe";
export var wrapper = "banner-module--wrapper--mw98S";