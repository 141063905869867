import React from "react"

import Container from "@ecom/ui/components/Container"
import MapImg from "./Img/Map"

import * as styles from "./map.module.scss"

type BannerProps = {
  title?: string
  orderNum?: number
}

const defaultTitle = "Ищи вязаную шапку в парке ВДНХ, возле «Бурана»"

export default function Map({ title = defaultTitle, orderNum }: BannerProps) {
  return (
    <section className={styles.section} data-exclude={orderNum} id="map">
      <Container className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <MapImg alt="bg image" className={styles.img} />
      </Container>
    </section>
  )
}
