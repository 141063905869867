import React from "react"

import type { GatsbyImageProps } from "gatsby-plugin-image"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import ShubaImg from "./Img/ShapkaImg"

import * as styles from "./banner.module.scss"

type BannerProps = {
  buttonText?: string
  title?: string
  description?: string
  orderNum?: string
  Img?: React.FC<Omit<GatsbyImageProps, "image">>
  bottomText?: React.ReactNode
}

const defaultTitle = `
Получи стикер 
  <br/>
  в зимнем отделении Халвы!
`

const defaultDescription = `
Ищи огромную вязаную шапку на ВДНХ. 
Со&nbsp;стикером выдаем вкусный бонус.
`

const defaultBottomText = (
  <>
    <p>
      Оформи платежный <br className="d-sm-none" /> стикер и получи «Халвакао»{" "}
      <br className="d-mob-only-none d-tab-only-none" />и зефирный бонус в подарок!
    </p>
    <span>с 10.12.2023 по 08.01.2024</span>
  </>
)

const handleClick = () => {
  const twoChoice = document.getElementById("TwoChoice")
  if (twoChoice) {
    twoChoice.scrollIntoView({ behavior: "smooth", block: "center" })
  }
  pushToDataLayer({
    event: "buttonClick",
    name: "Получить",
    placement: "banner_button_halva",
  })
}

export default function BannerWithDescription({
  buttonText = "Получить",
  title = defaultTitle,
  description = defaultDescription,
  orderNum,
  Img = ShubaImg,
  bottomText = defaultBottomText,
}: BannerProps) {
  return (
    <>
      <section className={styles.section} data-exclude={orderNum}>
        <Container className={styles.container}>
          <div className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <Button onClick={handleClick} className={styles.btn}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={styles.btnBlock}>
        <Container className={styles.textBlockContainer}>
          <div className={styles.wrapper}>
            <div className={styles.textBlock}>{bottomText}</div>
            <Button onClick={handleClick} className={styles.mobBtn}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </div>
    </>
  )
}
